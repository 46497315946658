<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Diego Andres Macias Arciniegas                                ###### -->
<!-- ###### @date: Enero 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div>
    <!-- content -->

    <HomologadorTabla ref="tablaHomolocacion" />

  </div>
</template>
  
  <!-- #################################################################################### -->
  <!-- ###### Sección de Scripts                                                     ###### -->
  <!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from 'vuex'
import { Role } from "../../../../router/role.js";
import HomologadorTabla from "@/components/HomologadorTabla.vue";

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
  name: 'HistoricoHomologadorVer',
  data() {
    return {
      id: null,
      ocultarTabla: true,
      tab: 1,
      verMenu: true,
      roles: Role,
      userRoles: {},
      submenu: [
        {
          name: "Homologador",
          icon: "compare",
          rol: Role.Herramientas_Homologador
        }
      ],
    }
  },
  created() {
    this.userRoles = this.auth.roles;
  },
  computed: {
    ...mapState(['auth', 'notify', 'busy']),
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  methods: {
    ...mapMutations(['hideNotify', 'showSuccess', 'showBusy', 'hideBusy', 'snackbar']),
  },
  watch: {
    deep: true,
  },
  mounted() {
    this.id = this.$route.params.id;
    this.$http.get(`msa-tools/api/item-cotizacion/${this.id}?page=1&limit=10&search=`).then(response => {
      this.$refs.tablaHomolocacion.recibirInformacion(response, this.time);
    }).catch(error => {
      if (error.response.status == 400) {
        this.snackbar('La cotización solicitada no existe', 'error', 2000)
        return;
      }
      this.snackbar('Error al cargar la cotización', 'error', 2000)
    })
  },
  components: {
    HomologadorTabla,
  }
}
</script>
  
  <!-- #################################################################################### -->
  <!-- ###### Sección de Estilos                                                     ###### -->
  <!-- #################################################################################### -->
<style scoped lang="scss">
.boton-tools {
  padding: 0px 10px 0px 10px !important;

}

.icono-tools {
  align-items: baseline;
  font-size: 56px !important;
  margin-top: 0px !important;
}

.theme--light.v-label {
  color: black !important;
}

.dropbox {
  outline: 2px dashed grey;
  /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  height: 100px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 18px 0;
}

.result-icon {
  padding-right: 7px;
}

.toolbar {
  height: 60px !important;
}

.ui.dropdown .menu {
  position: static !important;
}

.help-text {
  font-style: italic;
}

.texto-negro {
  color: black !important;
  position: absolute;
}

.fila-seleccionada {
  background-color: #d2f3d5 !important;
}

.ocultar-elemento {
  display: none;
}

.boton-descarga {
  height: 22px !important;
}

.padding-filtro {
  padding: 0px;
}

::v-deep .tabla-tools div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tabla-tools div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .tabla-tools div table tbody tr td {
  font-size: 0.7rem !important;
}

::v-deep .tabla-tools div table tbody tr td span div {
  font-size: 0.7rem !important;
}

::v-deep .tabla-tools .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>tbody>tr>th,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>th {
  padding: 0px 1px;
}


::v-deep .seleccionar-multiple .v-select__selection--comma {
  max-width: 98% !important;
}

.modificado-tabla {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.v-application .texto-cabecera p {
  margin-bottom: 0px !important;
}

.hijo {
  font-size: 0.8rem;
}

a {
  text-decoration: none;
}
</style>